require('../css/app.scss');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

require('bootstrap/dist/js/bootstrap.bundle');

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMoment from 'vue-moment';
import VueFilters from 'vue2-filters';
import Stock from './components/Stock.vue';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMoment);
Vue.use(VueFilters);

const routes = [
    {path: '/', component: Stock},
];

const router = new VueRouter({
    mode: 'history',
    routes
});

new Vue({
    router,
    render: h => h(Stock)
}).$mount('#app');