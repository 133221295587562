<template>
    <table class="table">
        <thead class="thead-dark">
        <tr>
            <th>Card</th>
            <th>Qty</th>
            <th>Condition</th>
            <th>Price</th>
            <th>Offers</th>
            <th>Update</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="stock in stockList">
            <tr v-bind:class="(stock.status === 'success') ? 'table-success' : 'table-danger'">
                <td>
                    <span v-bind:class="'flag-icon flag-icon-'+stock.language_code"></span><span
                        v-if="stock.is_foil"> <i class="fas fa-star" style="color: #ebeb34"></i></span>
                    {{ stock.product_name }}<br/>
                    <small>{{ stock.expansion }}</small>
                    <br>
                </td>
                <td>
                    {{ stock.qty }}
                    <span v-if="stock.is_playset">x 4<br>(Playset)</span>
                </td>
                <td>{{ stock.min_condition }}</td>
                <td>
                    <div>{{ stock.price|currency('€') }}</div>
                    <div>
                        <span><small>Min: {{ stock.price_min|currency('€') }}</small></span><br/>
                        <span><small>Avg: {{ stock.price_avg|currency('€') }}</small></span><br/>
                        <span><small>Max: {{ stock.price_max|currency('€') }}</small></span>
                    </div>
                </td>
                <td>
                    <div v-if="stock.offers_position && stock.offers_total">
                        {{ stock.offers_position }} / {{ stock.offers_total }}<br>
                        <a data-toggle="modal" v-bind:data-target="'#modalImprint' + stock.id">Offers</a>
                        <div class="modal fade" v-bind:id="'modalImprint' + stock.id" tabindex="-1" role="dialog"
                             aria-labelledby="modalImprintTitle" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="modalImprintTitle"><span
                                                v-bind:class="'flag-icon flag-icon-'+stock.language_code"></span>{{stock.product_name}}
                                        </h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <table>
                                            <template v-for="(offer, index) in stock.offers">
                                                <tr>
                                                    <td>{{ index+1}}</td>
                                                    <td>{{ offer.seller.username }}</td>
                                                    <td>{{ offer.price|currency('€') }}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <span v-if="stock.updated_at">
                        {{ stock.updated_at.date| moment("DD.MM.Y HH:mm:ss") }}
                    </span>
                    <span v-else>
                        -
                    </span>
                </td>
                <td>
                    <a @click="refreshRow(stock.id)"><i class="fas fa-sync"></i></a>
                </td>
            </tr>
        </template>
        </tbody>
    </table>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                stockList: {}
            }
        },
        methods: {
            refreshRow: function (stockId) {
                axios
                .get('https://magiccenter.alfapipsch.de/api/stock/sync/single/' + stockId)
                //    .get('http://localhost.magiccenter/api/stock/sync/single/' + stockId)
                    .then(response => {
                        this.stockList[stockId] = response.data
                    })
                    .catch(error => console.log(error))
            }
        },
        async created() {
            const response = await axios.get('https://magiccenter.alfapipsch.de/api/stock/list');
            //const response = await axios.get('http://localhost.magiccenter/api/stock/list');
            this.stockList = response.data
        }
    }
</script>